import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AuthService} from '../users/auth.service';
import {Action, Store} from '@ngrx/store';
import {Config} from './config';
import {Subscription, Observable} from 'rxjs';

export const SET_CONFIG = 'SET_CONFIG';

export function configReducer(state: Config = null, action: Action & { payload?: any }): Config {
    switch (action.type) {
        case SET_CONFIG:
            return action.payload;
        default:
            return state;
    }
}

@Injectable({
    providedIn: 'root'
})
export class ConfigService implements OnDestroy {
    private configUrl = 'rest/config';
    private _config: Config;
    currentUserSubscription: Subscription;

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private store: Store<any>) {

        this.currentUserSubscription = this.auth.currentUser.subscribe(() => {
            this.loadConfig().then(config => {
                this._config = config;
                this.setConfig(config);
            });
        });
    }

    ngOnDestroy() {
        this.currentUserSubscription.unsubscribe();
    }

    async loadConfig() {
        return this.http.get(this.configUrl).toPromise()
            .then(result => result as Config);
    }

    async refresh() {
        this.setConfig(null);

        const params = new HttpParams().set('refresh', 'true');

        return this.http.get(this.configUrl, {params}).toPromise()
            .then(result => result as Config)
            .then(config => this.setConfig(config));
    }

    private setConfig(config: Config) {
        this.store.dispatch({
            type: SET_CONFIG,
            payload: config
        });
    }

    get config(): Observable<Config> {
        return this.store.select('config');
    }

    async updateSiteConfigItem(key: string, value: any) {
        this._config.site[key].value = value;
        this.setConfig(Object.assign({}, this._config));

        const params = new HttpParams().set('key', key).set('value', value);

        return this.http.put(this.configUrl + '/site', null, {params}).toPromise();
    }
}
