import { Component } from '@angular/core';

@Component({
  selector: 'vni-root',
  templateUrl: './vni.component.html',
})
export class VniComponent {
  constructor() {
  }
}
